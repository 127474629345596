.chat-page {
  min-height: 70vh;
  @include flexStartLeft(row);
  height: max-content;
  padding-bottom: 2rem;
  .chat-content {
    padding: 0rem 2rem;
    min-height: 100%;
    @include flexStartLeft(column);
    width: 100%;
    .chat-title {
      padding-bottom: 1rem;
      border-bottom: 2px solid $lgray;
      text-transform: capitalize;
    }
    .chat-messages {
      flex: 1;
      @include flexStartLeft(column);
      max-height: 60vh;
      overflow-y: auto;
      padding-right: 1rem;
      position: relative; // Add this
      scroll-behavior: smooth; // Add this
      
      .message {
        width: 100%; // Add this
        margin-bottom: 0.5rem; // Add this for spacing between messages
        .message-content {
          font-weight: 500;
        }
        .time-stamp {
          font-size: small;
        }
        &.sender {
          text-align: right;
        }
      }
    }
  }
}

.chat-input {
  input {
    width: 100%;
    border: none;
    @include shadow();
    height: 50px;
    padding: 1rem;
    border-radius: 1rem;
  }
}

.chats-list {
  width: 40%;
  border-right: 1px solid $lgray;
  padding-right: 1rem;
  .header {
    @include flexBetween(row);
    .message-buttons {
      @include flexCenter(row);
      gap: 1rem;
      button {
        @include flexCenter(row);
        background-color: $slgray;
        border: 0;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;
        &:hover {
          background-color: $lgray;
        }
      }
    }
  }
  .filter-messages {
    @include flexStartLeft(row);
    gap: 1rem;
    margin-bottom: 2rem;
  }
}
