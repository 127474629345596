@mixin flexCenter($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

@mixin flexBetween($direction) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: $direction;
}

@mixin flexStart($direction) {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: $direction;
}

@mixin flexStartLeft($direction) {
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: $direction;
}

@mixin flexEnd($direction) {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: $direction;
}
