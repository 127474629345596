.wallet-button {
  position: relative;
  border: none;
  .wallet-modal {
    position: absolute;
    bottom: -0.5rem;
    right: -6rem;
    transform: translateY(100%);
    background-color: white;
    padding: 1.5rem;
    @include shadow();
    border-radius: 0.5rem;
    @include respond-to(md) {
      right: 0;
    }
    .balance {
      @include flexCenter(row);
      gap: 1rem;
      .balance-item {
        @include shadow-sm();
        @include flexStartLeft(column);
        justify-content: center;
        width: 145px;
        height: 80px;
        border-radius: 0.5rem;
        padding: 0 1rem;
        h4 {
          margin: 0;
        }
      }
    }
    .transaction {
      display: flex;
      justify-content: center;
      align-items: start;
      padding-top: 1rem;
      margin-top: 1rem;
      gap: 1rem;
      border-top: 1px solid $lgray;
      .content {
        @include flexBetween(row);
        flex-grow: 1;
        .type {
          justify-content: start;
          > * {
            margin: 0;
          }
          p {
            color: $gray;
          }
        }
        .value {
          align-self: flex-end;
        }
      }
    }
  }
}
