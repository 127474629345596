.setting-options {
  .setting-option {
    padding: 1rem 0;
    &:last-of-type {
      border-bottom: 1px solid $lgray;
      margin-bottom: 2rem;
    }
  }
}

