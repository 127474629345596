// @import '../../../../style/build/variables/variables';
.account-setting-cards {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  .account-setting-card {
    text-decoration: none;
    color: black;
    transform: translate(0rem);
    transition: all 0.2s;
    .content {
      height: 100%;
      border-radius: 0.5rem;
      padding: 1rem;
      box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      h4 {
        font-weight: bold;
        font-size: medium;
      }
      p {
        font-size: small;
      }
    }
  }
  .account-setting-card:hover {
    transform: translateY(-1rem);
    background-color: $lwhite;
  }
}

@media (max-width: 930px) {
  .account-setting-cards {
    grid-template-columns: repeat(1, 1fr);
    .account-setting-card:hover {
      transform: translateY(0);
      background-color: transparent;
    }
  }
}
