.generic-banner {
    background-color: #9b27af;
    color: white;
    padding: 72px 0 60px;
    text-align: center;

    h2 {
        text-align: center;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        max-width: 386px;
        margin: 0 auto 32px;
        text-align: center;
    }
}