.nav-btn.dark.user-icon {
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  width: 3rem;
  height: 3rem;
}

.user-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  // right: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 150px;
  padding: 10px 0;
  z-index: 1000;
}

.user-dropdown a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
}

.user-dropdown a.navbar-link-mobile {
  display: none;
}

.user-dropdown a:hover {
  background-color: #f1f1f1;
}

.user-dropdown hr {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
}
