.hero-banner {
  position: relative;
  width: 100%;
  height: 500px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    position: absolute;
    @include flexCenter(column);
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(213, 213, 213, 0.5) 0%,
      #efe9e4 48.5%
    );

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
      text-align: left;
      width: 90%;
      @include respond-to(md) {
        font-size: 2rem;
        line-height: 3rem;
        width: 80%;
      }
    }
    p {
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1rem;
      text-align: left;
      margin-bottom: 1rem;
      @include respond-to(sm) {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 3rem;
        width: 70%;
      }
    }
    .submit-button {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}
.specialize {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 2rem;
  margin-top: 3rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  @include respond-to(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  .text {
    padding: 2rem 0;
    .spec {
      @include flexStart(row);
      align-items: center;
      gap: 1rem;
      p {
        margin: 0;
        width: 80%;
      }
      .spec-icon {
        @include flexCenter(row);
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: $lgray;
      }
    }
  }
}

.about-us {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 2rem;
  margin-top: 3rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  @include respond-to(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  .text {
    padding: 2rem 0;
  }
}

.numbers-container {
  background-color: $lred;
  @include flexCenter(column);
  padding: 1rem 0;
  margin: 2rem 0;

  @include respond-to(xl) {
    transform: translateY(-50%);
  }
  .numbers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 2rem;
    @include respond-to(md) {
      grid-template-columns: repeat(3, 1fr);

      justify-content: start;
      align-items: start;
    }
    @include respond-to(xl) {
      grid-template-columns: repeat(7, 1fr);
    }
    h2 {
      text-align: center;
      font-family: Poppins;
      font-size: 2.5rem;
      font-weight: 600;
      color: $red;
      @include respond-to(lg) {
        text-align: left;
      }
    }
    p {
      text-align: center;
      @include respond-to(lg) {
        text-align: left;
      }
    }
  }
}
.join-us {
  @include flexCenter(column);
  h2 {
    line-height: 2rem;
    div {
      display: inline-block;
      background-color: $red;
      color: $white;
      padding: 0.5rem 0.5rem;
      transform: skew(-20deg);
      margin-right: 0.5rem;
      span {
        display: inline-block;
        transform: skewX(20deg);
      }
    }
  }
}
.platform {
  background-color: $lwhite;
  margin-top: 2rem;
  .container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 2rem;
    @include respond-to(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    .text {
      grid-area: 1 / 1 / 2 / 3;
      h2 {
        font-size: 2rem;
      }
      p {
        margin-bottom: 2rem;
      }
    }
    .phone {
      @include flexCenter(row);
      padding: 2rem 0;
    }
  }
}
.get-started {
  background-color: $slred;
  padding: 3rem 0;
  .container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 2rem;

    @include respond-to(lg) {
      grid-template-columns: repeat(5, 1fr);
      .text {
        grid-area: 1 / 1 / 2 / 4;
      }
      .get-started-card {
        grid-area: 1 / 4 / 2 / 6;
      }
    }
  }
  .text {
    h2 {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
      @include respond-to(lg) {
        text-align: left;
      }
    }
    span {
      color: $red;
    }
  }
  .get-started-card {
    background-color: $white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    h3 {
      margin-bottom: 2rem;
    }
    .check-item {
      @include flexStartLeft(row);
      gap: 1rem;
      .check-icon {
        color: $red;
      }
    }
    .button-container {
      @include flexCenter(row);
      padding: 1rem 0;
      .submit-button {
        margin: 0;
        width: 100%;
        border-radius: 1.2rem;
      }
    }
  }
}
