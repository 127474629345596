.transactions-tabel {
  width: 100%;
  @include shadow();
  border-radius: 0.5rem;
  margin-top: 2rem;
  table {
    width: 100%;
    padding: 0;
    border-radius: 0.5rem;
    overflow: hidden;
    border-spacing: 0;
    thead {
      tr {
        background-color: $lgray;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        th {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          padding: 1rem 2rem;
        }
      }
    }
    td,
    td > p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding: 0.5rem 2rem;
    }
    tr {
      border-bottom: 1px solid $lgray;
    }
  }
}
