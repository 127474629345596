.notification-page {
  min-height: 70vh;
}
.notifications-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.notification {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.icon {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  background-color: $black;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
}

.text {
  flex: 1;
}

.text p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.new-indicator {
  color: #9a00ff;
  margin-left: 5px;
}

.time {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #888;
}

.close-btn {
  background: none;
  border: none;
  color: #888;
  font-size: 18px;
  cursor: pointer;
}

.close-btn:hover {
  color: #000;
}

.time-group {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.tabs-router {
  @include flexStartLeft(row);
  width: 100%;
  border-bottom: 1px solid $lgray;
  gap: 10px;
  margin-bottom: 20px;
  .tab {
    cursor: pointer;
    color: $gray;
  }
  .active {
    border-bottom: 2px solid $black;
    color: black;
  }
}
