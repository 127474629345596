.generic-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(26, 26, 26, 0.2);
  z-index: 310;
  .content {
    @include flexStart(column);
    flex-direction: column !important;
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    max-height: 80vh;
    max-width: 300px;
    min-width: 90vw;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    gap: 1rem;
    overflow: auto;
    h2 {
      margin-bottom: 1rem;
    }
    p {
      word-wrap: break-word;
    }
    button {
      margin-top: 1rem;
      &.rdp-day_button {
        margin-top: auto;
      }
    }
    .closeIcon {
      align-self: flex-start;
    }
    @include respond-to(md) {
      // max-width: 600px;
      min-width: 600px;
      max-height: 90vh;
    }
  }
  .body {
    width: 100%;
  }
}
