.guests-modal {
  .title {
    margin-bottom: 2rem;
  }
  .guest {
    @include flexBetween(row);
    margin-bottom: 1rem;
    .name {
      .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .qty {
      @include flexCenter(row);
      gap: 1rem;
      p {
        margin: 0;
      }
      button {
        margin: 0;
        border: 1px solid $lgray;
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        cursor: pointer;
        &:hover {
          background-color: $lgray;
        }
        &:disabled {
          color: $lgray;
          cursor: not-allowed;
        }
      }
    }
  }
}
