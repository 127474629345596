.search-section-container {
  width: 100%;
  .search-bar {
    transform: translateY(-2rem);
    box-shadow: none;
    position: sticky;
  }
}

.all-properties-page {
  width: 100%;
  @include flexCenter(column);
  flex-direction: column-reverse;
  @include respond-to(lg) {
    @include flexCenter(row);
    align-items: start;
    justify-content: start;
    gap: 2rem;
  }
}
.all-properties-map {
  min-width: 400px;
  position: relative; /* Defines the boundary for sticky positioning */
  @include respond-to(lg) {
    width: auto;
  }
  .fixed {
    position: sticky;
    height: 600px;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    &.expanded {
      @include respond-to(lg) {
        position: fixed;
        top: 8rem;
        height: 75vh;
      }
    }
  }
  iframe {
    border: none;
    border-radius: 1rem;
    width: 100%;
    @include respond-to(lg) {
      height: 750px;
      width: auto;
    }
  }
  .map-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.allProperties-container {
  width: 100%;
  margin-bottom: 2rem;
  min-height: 100vh;
}
.allProperties {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-row-gap: 1rem;
  @include respond-to(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
  @include respond-to(lg) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
  }
  @include respond-to(xl) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  > * {
    flex: 1;
  }
  &.one-col {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    @include respond-to(md) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include respond-to(lg) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include respond-to(xl) {
      grid-template-columns: repeat(1, 1fr);
    }
    > * {
      flex: 1;
    }
    .card {
      width: 100%;
      .card-link {
        width: 100%;
        height: 150px !important;
        overflow: hidden;
        display: grid;
        height: auto;
        flex-direction: row;
        border-radius: 0;
        grid-template-columns: 150px 1fr;
        @include respond-to(sm) {
          grid-template-columns: 1fr 2fr;
        }
        @include respond-to(md) {
          height: 200px !important;
        }
        @include respond-to(lg) {
          height: 200px !important;
        }
        @include respond-to(xl) {
          height: 220px !important;
        }
      }
      .card-image {
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow: hidden;

        img {
          object-fit: cover;
        }
        @include respond-to(md) {
          width: auto;
        }
      }
    }
  }
}
.view-type {
  @include flexBetween(row);
  margin-bottom: 1rem;
  gap: 1rem;
  button {
    background-color: transparent;
    border: none;
    font-size: 1.4rem;
    cursor: pointer;
    &:hover {
      color: $gray;
    }
  }
}
