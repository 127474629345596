.mobileNav {
  @include respond-to(md) {
    display: none;
  }
}
.header-margin {
  margin-top: 5.5rem;
  @include respond-to(md) {
    display: none;
  }
}

.mobile-search {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 50px;
  @include shadow();
  border-radius: 0.5rem;
  padding: 0.4rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  .search-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .mobile-search-item {
    @include flexCenter(row);
    width: max-content;
    gap: 0.3rem;
    padding: 0.1rem 0.5rem;
    border-radius: 0.5rem;
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 300;
      color: $gray;
    }
    &.gray {
      background-color: $slgray;
    }
  }
}

.mobile-search-modal {
  position: fixed;
  inset: 0;
  height: 100vh;
  overflow: auto;
  background-color: white;
  z-index: 100;
  padding: 1rem;
  // Make the modal use flexbox
  display: flex;
  flex-direction: column;
  .back-button {
    cursor: pointer;
  }
}

.modal-search-item {
  @include shadow();
  z-index: 10;
  margin-bottom: 0.7rem;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  .item-title {
    padding: 0.8rem 0;
    cursor: pointer;
    @include flexStart(row);
    gap: 0.5rem;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
    svg {
      stroke: $gray;
      width: 26px;
      height: 26px;
    }
  }
  .amenities-picker {
    position: relative;
    box-shadow: none;
    top: 0;
  }
}
.modal-search-item-body {
  @include flexCenter(row);
  overflow: hidden;
}
.modal-search-item-body ul {
  height: 100%;
  width: 100%;
  overflow: auto;
  @include flexStartLeft(column);
  list-style: none;
  gap: 0.5rem;
  margin: 0;
  margin-top: 1rem;
  overflow: auto;
  border-top: 1px solid $lgray;
  padding-top: 1rem;
  ul {
    @include flexStartLeft(column);
    gap: 0.5rem;
    list-style: none;
    margin: 0.5rem 1rem;
  }
  li {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    span {
      margin-left: 0.5rem;
      font-size: 14px;
      font-weight: 500;
      color: $gray;
    }
    div {
      @include flexStart(row);
      gap: 0.5rem;
      h5 {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        .check-mark {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.amenities-preview {
  @include flexStart(row);
  gap: 0.5rem;
  list-style: none;
  margin: 0;
  h5 {
    font-size: 0.8rem;
    background-color: $slgray;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: max-content;
  }
  h4 {
    font-size: 0.8rem;
    background-color: $slgray;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
}

.modal-footer {
  margin-top: auto;
  .button-container {
    padding: 0;
  }
}

.property-types-mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
  width: 100%;
  justify-items: center;
  .type-mobile {
    @include flexCenter(column);
    gap: 0.5rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 0.5rem;
    width: 90px;
    height: 90px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    svg {
      stroke: black;
    }
    &.selected {
      color: white;
      background-color: black;
      svg {
        stroke: white;
      }
    }
  }
}
