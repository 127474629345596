.error-body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-body * {
  margin: 0;
  padding: 0;
}
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  h1 {
    text-align: center;
  }
  p {
    font-family: poppins;
    text-align: center;
    font-size: large;
  }

  button {
    margin-top: 1rem;
    font-family: poppins;
    padding: 0.5rem 1rem;
  }
}
