// @import '@style/build/mixins/flex';
// @import '../../../../style/build/mixins/shadow.scss';
.profile-page {
  .sections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 4rem;
    grid-row-gap: 0px;
    .section1 {
      grid-area: 1 / 1 / 2 / 2;
      @include flexStartLeft(column);
      gap: 2rem;
    }
    .section2 {
      grid-area: 1 / 2 / 2 / 4;
    }
  }
}
.profile-info {
  @include flexCenter(column);
  @include shadow();
  padding: 1.5rem 0;
  border-radius: 0.5rem;
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 500;
  }
  h2 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 0.5rem;
    color: $gray;
  }
  .avatar {
    @include flexCenter(row);
    background-color: black;
    color: white;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
    h1 {
      margin: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .socials {
    @include flexCenter(row);
    gap: 1rem;
    color: $gray;
    font-size: 2rem;
    margin: 1rem 0;
    a {
      color: $gray;
    }
  }
}
.tags-container {
  @include flexStartLeft(row);
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 14px;
  font-weight: 400;
  color: $gray;
  margin-bottom: 1rem;
  .tag {
    font-size: 1rem;
    font-weight: 500;
    @include flexStartLeft(row);
    align-items: center;
    gap: 1rem;
    color: black;
    border: 1px solid $lgray;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    &.edit {
      cursor: pointer;
      &:hover {
        border: 1px black solid;
        background-color: $slgray;
      }
    }
    &.selected {
      border: 1px black solid;
      background-color: $slgray;
    }
  }
}
.confirmed-info {
  @include flexStartLeft(column);
  gap: 1rem;
  padding: 2rem;
  border: 1px solid $lgray;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  .confirmed-item {
    @include flexStartLeft(row);
    align-items: center;
    gap: 1rem;
    h5 {
      margin: 0;
    }
  }
}
.section2 {
  @include flexStartLeft(column);
  gap: 0.5rem;
  h2 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }
}
.information {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1rem;

  .info-item {
    @include flexStartLeft(row);
    align-items: center;
    gap: 1rem;
    svg {
      color: $gray;
    }
    p {
      margin: 0;
      font-weight: 400;
    }
  }
}
.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.2rem;
  margin: 2rem 0;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 0.5rem;
  }
}
