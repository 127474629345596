.hotels-nearby {
  margin: 2rem 0;
}
.hotels-container {
  @include flexCenter(row);
  padding: 0 0.5rem;
  .hotels {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    > * {
      flex: 1;
    }
    @include respond-to(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include respond-to(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
