.location-picker {
  position: absolute;
  z-index: 100;
  top: 3rem;
  height: max-content;
  background-color: white;
  border-radius: 1rem;
  list-style-type: none;
  padding: 1rem 0;
  margin: 0;
  width: 100%;
  @include shadow();
  span {
    margin-left: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    color: $gray;
  }
  li {
    width: 100%;
    padding: 0.5rem 1rem;
    @include flexStartLeft(row);
    margin: 0;
    gap: 1rem;
    cursor: pointer;

    div {
      @include flexStart(row);
      gap: 0.5rem;
      h5 {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        .check-mark {
          margin-left: 0.5rem;
        }
      }
    }

    &:hover {
      background-color: $slgray;
    }
  }
  > li {
    position: relative;
    ul {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(100%, -2rem);
      background-color: white;
      border-radius: 1rem;
      padding: 1rem 0;
      list-style-type: none;
      z-index: 200;
      @include shadow();
      li {
        text-wrap: nowrap;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &:hover ul,
    ul:hover {
      display: block;
    }
  }
}
