.room-properties {
  display: flex;
  padding: 1rem 1rem 0 1rem;
  background-color: $bggray;
  border-bottom: 2px solid $gray;
  margin-bottom: 2rem;
  @include flexStartLeft(column);
  > * {
    flex: 3;
  }
  b {
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
  }
  @include respond-to(md) {
    @include flexStartLeft(row);
    margin-bottom: 0rem;
    height: max-content;
    b {
      margin-top: 0rem;
      text-align: left;
    }
  }
  .table-head {
    text-align: center;
    width: 100%;
  }
  .preview {
    flex: 3.5;
    // display: none;
    @include flexStartLeft(column);
    margin-right: 1rem;
    b {
      font-weight: 700;
    }

    @include respond-to(lg) {
      display: flex !important;
    }
    @include respond-to(md) {
      display: none;
    }
    .preview-imgs {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 7px;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
      & > * {
        &:hover {
          cursor: pointer;
          filter: brightness(0.7);
        }
      }
      .img1 {
        grid-area: 1 / 1 / 4 / 4;
      }
      .img2 {
        grid-area: 4 / 1 / 5 / 2;
      }
      .img3 {
        grid-area: 4 / 2 / 5 / 3;
      }
      .img4 {
        grid-area: 4 / 3 / 5 / 4;
      }
    }
    .facilities {
      margin: 0.5rem 0;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      p {
        margin-right: 1rem;
      }
      .facility {
        width: fit-content;
        padding: 0.2rem;
        margin-bottom: 0.2rem;
        background-color: $lgray;
        border-radius: 0.3rem;
        .facility-icon {
          @include flexCenter(row);
          width: 18px;
          > * {
            width: 100%;
          }
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
  .details {
    margin-right: 0.1rem;
    min-height: 100%;
    @include flexStartLeft(column);

    .content {
      height: 100%;
      margin-top: 1rem;
      background-color: $white;
      border-radius: 0.5rem 0 0 0.5rem;
      padding: 1rem;
      p {
        font-size: 15px;
      }
      ul {
        li {
          font-size: 14px;
        }
      }
    }
  }
  .sleeps {
    flex: 1;
    margin-right: 0.1rem;
    min-height: 100%;
    @include flexStartLeft(column);
    .content {
      height: 100%;
      padding: 1rem 0.5rem;
      margin-top: 1rem;
      @include flexStart(column);
      background-color: $white;
    }
  }
  .price {
    min-height: 100%;
    @include flexStart(column);
    margin-right: 0.1rem;
    .content {
      height: 100%;
      text-align: left;
      padding: 1rem;
      background-color: $white;
      width: 100%;
      .price-value {
        text-align: right;
        p {
          margin: 0;
          &.line-through {
            text-decoration: line-through;
          }
          &.gray {
            color: $gray;
            font-size: small;
          }
        }
      }
      &:first-of-type {
        margin-top: 1rem;
        margin-bottom: 0.1rem;
        height: max-content;
      }
    }
  }
  .count {
    flex: 1;
    margin-right: 0.1rem;
    min-height: 100%;
    @include flexStartLeft(column);
    .content {
      height: 100%;
      padding: 1rem;
      margin-top: 1rem;
      @include flexStart(column);
      background-color: $white;
      p {
        width: 25px;
        height: 25px;
        @include flexCenter(row);
      }
    }
  }
  .book {
    min-height: 100%;
    @include flexStart(column);
    .content {
      @include flexStart(column);
      height: 100%;
      margin-top: 1rem;
      // padding: 1rem;
      background-color: $white;
      width: 100%;
      border-radius: 0 0.5rem 0.5rem 0;
      button {
        width: 100%;
        margin-bottom: 0.5rem;
      }
      p {
        color: $blue;
        font-size: 12px;
      }
    }
  }
  .label {
    background-color: $red;
    color: $white;
    padding: 0.2rem 0.4rem;
    transform: skew(-20deg);
    border-radius: 0.3rem;
    margin-bottom: 0.3rem;
    width: fit-content;
    @include flexCenter(row);
    span {
      font-size: 10px;
      margin: 0;
      transform: skew(20deg);
      font-weight: 500;
    }
    &.black {
      color: white;
      background-color: $black;
    }
  }
}
