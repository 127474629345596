.input-field {
  position: relative;
  input,
  select,
  textarea {
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
    border: 2px solid black;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;

    &.padding-top {
      padding-top: 1.5rem;
    }
    &.width-half {
      width: 150px;
    }
    &.auto {
      width: auto;
    }
  }

  label {
    margin-bottom: 12px;
    line-height: 27px;
    display: block;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
  .input-container {
    position: relative;
    @include flexStartLeft(row);
    align-items: center;
    position: relative;
  }
  .inner-label {
    position: absolute;
    top: 0;
    left: 1rem;
    padding: 0.3rem 0.8rem;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 400;
    color: $gray;
  }
  .right-label {
    padding: 0.3rem 0.8rem;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 400;
    color: $gray;
  }
  .double-input {
    @include flexStartLeft(row);
    gap: 1rem;
    &.horizontal {
      flex-direction: column;
      gap: 0;
    }
  }
  .phone-input {
    @include flexStartLeft(row);
    select {
      width: 100px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-with-button {
  position: relative;
  width: max-content;
  input {
    width: 400px !important;
    margin-bottom: 0 !important;
    padding: 1rem 1rem;
    padding-right: 150px;
  }
  .button-container {
    position: absolute;
    right: 0.5rem;
  }
}

.input-checkbox {
  @include flexStartLeft(row);
  gap: 1rem;
  margin-bottom: 20px;
  input {
    min-width: 20px;
    height: 20px;
    display: block;
  }
  label {
    display: block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
}

.btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 25px;
  text-align: center;
  display: block;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  &.btn-primary {
    background-color: #1a1a1a;
    color: #fff;
  }
}

.input-counter {
  @include flexStartLeft(row);
  align-items: center;
  gap: 1rem;
  button {
    @include flexCenter(row);
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 50%;
    font-size: 1.5rem;
  }
  p {
    margin: 0;
  }
}
