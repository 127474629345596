.edit-profile-page {
  .sections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 4rem;
    grid-row-gap: 0px;
    .section-edit1 {
      grid-area: 1 / 1 / 2 / 2;
      @include flexStartLeft(column);
      gap: 2rem;
    }
    .section-edit2 {
      grid-area: 1 / 2 / 2 / 4;
    }
  }
  .generic-modal {
    .content {
      max-width: 400px !important;
    }
  }
}

.section-edit1 {
  .avatar {
    position: relative;
    @include flexCenter(row);
    background-color: black;
    color: white;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-bottom: 1rem;
    h1 {
      margin-bottom: -1rem;
      font-size: 10rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .edit-avatar {
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translate(-50%, 0%);
      cursor: pointer;
    }
  }
}

.section-edit2 {
  @include flexStartLeft(column);
  gap: 2rem;
  h2 {
    font-size: 36px;
    font-weight: 500;
    margin: 0;
  }
  .information {
    .edit-info {
      @include flexBetween(row);
      width: 350px;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid $lgray;
      padding-bottom: 1rem;
      &:hover {
        cursor: pointer;
        border-color: black;
      }
    }
    .label {
      @include flexStartLeft(row);
      align-items: center;
      gap: 1rem;
      p {
        margin: 0;
      }
      svg {
        color: $gray;
      }
    }
  }
  .description {
    margin: 0;
    padding: 1rem;
    border: 1px solid $lgray;
    border-radius: 0.5rem;
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1.2rem;
    margin: 0;
    p {
      text-wrap: nowrap;
    }
    .img {
      position: relative;
      .delete {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
      img {
        width: 100%;
        height: 150px;
      }
    }
  }
  .tags {
    margin-bottom: 2rem;
  }
}

.info-edit-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);
  top: 0;
  left: 0;
  z-index: 10;
  @include flexCenter(row);

  .body {
    background-color: white;
  }
}
