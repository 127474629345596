.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1.5rem;
}

.pagination-button {
  font-size: 1.5rem;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover:not(.pagination-disabled) {
    color: #000;
  }

  &.pagination-disabled {
    color: #ccc;
    cursor: not-allowed;
  }

  .pagination-arrow {
    font-weight: bold;
  }
}

.pagination-list {
  display: flex;
  gap: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination-item {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  background: none;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: #000;
    border-color: #000;
  }

  &.pagination-active {
    color: #000;
    border-bottom: 2px solid #000;
  }
}

.pagination-dots {
  font-size: 1rem;
  color: #aaa;
}
