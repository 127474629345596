.setting-page-tabs {
  min-height: 100vh;
  padding-bottom: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: start;
  .inside-container {
    width: 50%;
  }
  .settings {
    .setting {
      border-bottom: 1px solid #eee;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      margin-top: 2rem;

      .setting-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .devices {
          margin-top: 1rem;
          .device {
            display: flex;
            .details {
              display: flex;
            }
          }
        }
        .edit-button {
          color: $blue;
          text-decoration: none;
        }
        .edit-button.danger {
          color: $red;
        }
        p {
          color: $gray;
          font-size: small;
        }
      }
      .form {
        .inputs {
          flex-direction: column;
          align-items: flex-start;
          .input-container {
            .input {
              width: 30rem;
              border-radius: 0.5rem;
              border: 1px solid $gray;
              padding: 0.5rem 1rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 930px) {
  .setting-page-tabs {
    flex-direction: column;
    .inside-container {
      width: 100%;
    }
  }
}
