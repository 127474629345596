.features-section {
  padding: 115px 0;
  text-align: start;
  background: $lwhite;

  .features-grid {
    display: grid;
    gap: 1rem;
    @include respond-to(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .features-header {
    margin-bottom: 100px;
    text-align: center;
    @include respond-to(md) {
      text-align: start;
    }
  }
  .feature-item {
    min-width: 200px;
    @include flexCenter(column);
    text-align: center;
    h3 {
      text-align: center;
    }
    @include respond-to(md) {
      align-items: start;
      text-align: start;
      h3 {
        text-align: start;
      }
    }
  }
}
