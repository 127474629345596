.account {
  min-height: 100vh;
  padding-bottom: 3rem;
  p {
    span {
      font-weight: bold;
    }
    a {
      font-weight: bold;
      color: black;
    }
  }
}

