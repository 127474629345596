.list-your-property-page {
  .get-started {
    .text {
      padding: 0;
      align-self: flex-start;
      margin-bottom: 2rem;
      @include respond-to(lg) {
        padding: 5rem 0;
        margin-bottom: 0;
      }
    }
  }
}
//----------------------------------------------------------------
.worldwide {
  @include flexCenter(row);
  position: relative;
  height: 600px;
  margin-bottom: 2rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include respond-to(lg) {
    height: 450px;
  }
  .content {
    @include flexCenter(column);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    padding: 3rem 0;
    > h2 {
      color: $black;
      margin-bottom: 5rem;
      width: 80%;
    }
    .worldwide-numbers {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      justify-content: center;
      align-items: start;
      @include respond-to(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      .number {
        @include flexCenter(column);
        margin-bottom: 1rem;
        @include respond-to(lg) {
          margin-bottom: 1rem;
        }
        h2 {
          font-size: 1.7rem;
          font-weight: 600;
          @include respond-to(lg) {
            font-size: 2.5rem;
          }
        }
        p {
          text-align: center;
          width: 70%;

          @include respond-to(lg) {
            width: 50%;
          }
        }
      }
    }
  }
}
//----------------------------------------------------------------

.split-image {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
  @include respond-to(lg) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  .text {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    .check-item {
      @include flexStartLeft(row);
      gap: 1rem;
      .check-icon {
        @include flexCenter(row);
        width: 25px;
        height: 25px;
        border: 1px $red solid;
        border-radius: 100%;
        color: $red;
      }
      p {
        width: 90%;
      }
    }
  }
}
//----------------------------------------------------------------

.advantages-section {
  margin-top: 2rem;
  padding: 2rem 0;
  background-color: $lwhite;
  .advantages {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    @include respond-to(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 3rem;
    }
    .advantage {
      @include flexCenter(column);
      text-align: center;
      h4,
      p {
        text-align: center;
        width: 70%;
      }
      h4 {
        margin-top: 2rem;
      }
      @include respond-to(lg) {
        align-items: flex-start;
        h4,
        p {
          text-align: left;
          width: 70%;
        }
      }
    }
  }
}
//----------------------------------------------------------------

.testimonials-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .testimonials {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0px;
    justify-content: space-between;
    @include respond-to(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    .testimonial {
      margin: 1rem 0;
      gap: 2rem;
      border-radius: 1rem;
      @include flexStartLeft(column);
      @include shadow();
      @include respond-to(lg) {
        @include flexStartLeft(row);
        height: 250px;
      }
      .img {
        background-color: $gray;
        width: 100%;
        height: 250px;
        border-radius: 1rem 1rem 0 0;
        @include respond-to(lg) {
          border-radius: 1rem 0 0 1rem;
          width: 250px;
          height: 100%;
        }
      }
      .content {
        padding: 0 2rem;
        @include flexStartLeft(column);
        justify-content: center;
        @include respond-to(lg) {
          padding: 0;
          width: 50%;
        }
      }
    }
  }
}
