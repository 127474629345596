.search-bar-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1rem;
  border-radius: 10px;
  background-color: #eef7f9;
  margin-bottom: 20px;
  position: relative;
  .search-input {
    @include shadow();
    border-radius: 8px;
  }
  .search-bar-button {
    @extend.submit-button;
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
  }
}

.input.lg {
  width: 100%;
  height: 85px;
  border-radius: 10px;
  text-align: left;
  min-height: 60px;
  width: 100%;
  > div {
    min-height: 80px;
  }
}
.form {
  margin-top: 1rem;
  .inputs {
    @include flexStartLeft(column);
    align-items: start !important;
    gap: 1rem;
    .double-input {
      @include flexCenter(row);
      gap: 1rem;
    }
    .input-container {
      position: relative;
      position: relative;
      @include flexStartLeft(column);
      justify-content: start;
      .input {
        border-radius: 0.5rem;
        border: 1px solid $gray;
        padding: 1.3rem 0.5rem 0.2rem 1rem;
        width: 15rem;
      }
      .label {
        position: absolute;
        top: 0.2rem;
        left: 1rem;
        color: $gray;
        font-size: small;
      }
    }
  }
}
.form.col {
  .inputs {
    flex-direction: column;
    align-items: start;
    .input-container {
      position: relative;
      position: relative;
      width: 100%;
      .input {
        width: 50%;
      }
    }
  }
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .checkbox {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
  }
}

@media (max-width: 930px) {
  .form {
    .inputs {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
}
