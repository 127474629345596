.personal-info-page {
  min-height: 100vh;
  .settings {
    .setting {
      border-bottom: 1px solid #eee;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      .setting-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          color: $gray;
          font-size: small;
        }
      }
      .form {
        margin-top: 1rem;
        .inputs {
          display: flex;
          align-items: center;
          gap: 1rem;
          .input-container {
            position: relative;
            .input {
              border-radius: 0.5rem;
              padding: 1.3rem 0.5rem 0.2rem 1rem;
              width: 15rem;
            }
            .label {
              position: absolute;
              top: 0.2rem;
              left: 1rem;
              color: $gray;
              font-size: small;
            }
          }
        }
      }
      .existing-numbers-container {
        .existing-numbers {
          margin-top: 1rem;
          .existing-number {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .warning {
          margin-top: 1rem;
          .text {
            p {
              color: $gray;
              width: 80%;
              font-size: small;
            }
          }
          .buttons {
            display: flex;
            gap: 1rem;
          }
        }
      }
    }
  }
}



