.p-1 {
    padding: 5px;
}

.p-2 {
    padding: 10px;
}

.p-3 {
    padding: 15px;
}

.p-4 {
    padding: 20px;
}

.p-5 {
    padding: 25px;
}

.pt-1 {
    padding-top: 5px;
}

.pt-2 {
    padding-top: 10px;
}

.pt-3 {
    padding-top: 15px;
}

.pt-4 {
    padding-top: 20px;
}

.pt-5 {
    padding-top: 25px;
}

/* Padding bottom */
.pb-1 {
    padding-bottom: 5px;
}

.pb-2 {
    padding-bottom: 10px;
}

.pb-3 {
    padding-bottom: 15px;
}

.pb-4 {
    padding-bottom: 20px;
}

.pb-5 {
    padding-bottom: 25px;
}

.pl-1 {
    padding-left: 5px;
}

.pl-2 {
    padding-left: 10px;
}

.pl-3 {
    padding-left: 15px;
}

.pl-4 {
    padding-left: 20px;
}

.pl-5 {
    padding-left: 25px;
}

.pr-1 {
    padding-right: 5px;
}

.pr-2 {
    padding-right: 10px;
}

.pr-3 {
    padding-right: 15px;
}

.pr-4 {
    padding-right: 20px;
}

.pr-5 {
    padding-right: 25px;
}

.px-1 {
    padding-left: 5px;
    padding-right: 5px;
}

.px-2 {
    padding-left: 10px;
    padding-right: 10px;
}

.px-3 {
    padding-left: 15px;
    padding-right: 15px;
}

.px-4 {
    padding-left: 20px;
    padding-right: 20px;
}

.px-5 {
    padding-left: 25px;
    padding-right: 25px;
}

.py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.py-4 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.py-5 {
    padding-top: 25px;
    padding-bottom: 25px;
}