.no-data-yet {
  @include flexCenter(row);
  padding: 1rem;
  border: 2px dashed $lgray;
  color: $gray;
  &.small {
    height: 250px;
  }
  &.big {
    height: 350px;
  }
  &.full {
    height: 100%;
    width: 100%;
  }
}
