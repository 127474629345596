.hotel-page-gallery {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: none;
  height: 500px;
  @include respond-to(md) {
    display: grid;
  }
  .div1 {
    grid-area: 1 / 1 / 3 / 3;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .div2 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .div3 {
    grid-area: 2 / 3 / 3 / 4;
  }
  .div4 {
    grid-area: 1 / 4 / 2 / 5;
    border-top-right-radius: 0.5rem;
  }
  .div5 {
    grid-area: 2 / 4 / 3 / 5;
    border-bottom-right-radius: 0.5rem;
  }
  .hotel-gallery-item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $lgray;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      filter: brightness(0.8);
      cursor: pointer;
    }
  }
  .gallery-button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
.hotel-page-gallery-mobile {
  width: 100%;
  height: 300px;
  .hotel-gallery-item-mobile {
    height: 300px;
  }
  @include respond-to(md) {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
}
.hotel-gallery-item-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
}

.slick-next {
  transform: translateX(-2rem) scale(1.5);
  width: 25px;
}
.slick-prev {
  z-index: 2;
  transform: translateX(2rem) scale(1.5);
  width: 25px;
}
