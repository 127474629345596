.not-found {
  background-image: url("/assets/imgs/error.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
  min-height: 150vh;
  .text {
    h1 {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      @include respond-to(md) {
        font-size: 6rem;
      }
    }
    p {
      margin-bottom: 3rem;
      font-weight: 400;
      width: 50%;
      @include respond-to(md) {
        width: 100%;
      }
    }
    .submit-button {
      padding: 1rem 3rem;
    }
  }
}
