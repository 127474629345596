$white: #fff;
$lwhite: #fafafa;
$black: #1a1a1a;
$hover-bg-color: #333;
$gray: #777e90;
$lgray: #e1e1e1;
$bggray: #f7f7f7;
$slgray: #efefef;
$blue: #05a3a6;
$sblue: rgba(5, 163, 166, 1);
$lblue: rgba(5, 163, 166, 0.1);
$red: #93278f;
$sred: #ee0614;
$lred: rgba(147, 39, 143, 0.16);
$slred: #efe9e4;
$green: #06b681;
$lgreen: rgba(6, 182, 129, 0.1);
