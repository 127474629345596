.back-button {
  position: absolute;
  top: 0rem;
  left: -3rem;
  z-index: 10;
  font-size: 1.5rem;
  @include flexCenter(row);
  cursor: pointer;

  margin: 0;
}
