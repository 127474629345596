.gallery-popup {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 300;
  @include flexCenter(row);
  background-color: $black;
  .content {
    // padding: 2rem 0;
    width: 90%;
  }
  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: $white;
    z-index: 100;
    &:hover {
      color: $gray;
    }
  }
}

.image-gallery-thumbnail-image {
  height: 70px !important;
  object-fit: cover;
  opacity: 0.7;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
}
