.date-picker-modal {
  .modal-body {
    width: 100%;
    .title {
      @include flexBetween(row);
    }
    .date-pickers {
      border: 1px solid $gray;
      border-radius: 1rem;
      @include flexCenter(row);
      gap: 2rem;
      padding: 1rem;
    }
    .button-container {
      @include flexEnd(row);
      gap: 1rem;
      margin-top: 1rem;
      border: none;
      .submit-button {
        margin: 0;
      }
    }
  }

  .duration {
    @include flexCenter(row);
    margin-bottom: 1rem;
    gap: 1rem;
    .check-in {
      @include flexCenter(row);
      gap: 2rem;
      border: 1px solid $gray;
      border-radius: 1rem;
      padding: 0.5rem;
    }
    .check-in-date {
      h5 {
        margin-bottom: 0.2rem;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
