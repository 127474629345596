.notification-setting-modal {
  .modal-options {
    margin-top: 2rem;
    .modal-option {
      display: flex;
      justify-content: space-between;
      min-width: 25rem;
    }
  }
}
