.review {
  img {
    border-radius: 100%;
    width: 60px;
  }
  .user {
    @include flexStart(row);
    gap: 1rem;
    margin-bottom: 1rem;
    .name {
      h4 {
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      span {
        font-size: 0.8rem;
        color: $gray;
      }
    }
  }
  .review-text {
    font-size: 0.8rem;
    font-weight: 400;
  }
}
