.hotel-page {
  min-height: 100vh;
}
.map {
  margin: 1rem 0;
  width: 100%;
  height: 400px;
  border: none;
}
.hotel-page-footer {
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  padding: 2rem 0;
  .content {
    @include flexStartLeft(row);
    > * {
      flex: 1;
    }
  }
  .list {
    height: 100%;

    justify-content: space-between;
    ul {
      list-style-type: none;
      margin: 0;
      font-weight: 300;
      font-size: small;
      button {
        margin: 0;
        color: $blue;
      }
      li:last-of-type {
        margin-top: 1rem;
      }
    }
  }
}

.split {
  display: grid;
  grid-template-columns: 1fr;
  @include respond-to(md) {
    grid-template-columns: 3fr 1fr;
  }
  grid-column-gap: 2rem;
  .left {
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
