.amenities-picker {
  position: absolute;
  z-index: 100;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 1rem;
  list-style-type: none;
  margin: 0;
  width: 100%;
  height: 500px;
  max-height: 500px;
  overflow-y: auto; // Ensure scroll only on Y-axis
  overflow-x: hidden; // Hide horizontal scroll
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  // Opt into new behavior for scrollbar-width
  & {
    scrollbar-width: thin; // Make scrollbar thinner
    scrollbar-color: $gray transparent; // Thumb color and track color
  }

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 10px; // Adjust the scrollbar width
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; // Transparent background to see the padding
    border-radius: 1rem; // Match the border-radius
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc; // Color of the thumb
    border-radius: 1rem; // Rounded scrollbar thumb
    border: 3px solid transparent; // Padding around the thumb
    background-clip: padding-box; // Ensure border appears outside the thumb
  }

  // Opt into new behavior for hover states
  &::-webkit-scrollbar-thumb:hover {
    background-color: #999; // Darker color when hovered
  }

  // Styling for checkboxes and list items
  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    appearance: none; /* Remove default checkbox styling */
    -webkit-appearance: none; /* For WebKit browsers */
    -moz-appearance: none; /* For Firefox */

    /* Styling the checkbox */
    border: 2px solid $lgray; /* Border color */
    border-radius: 5px; /* Rounded corners */
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    &:checked {
      background-color: #1a1a1a; /* Change the background when checked */
    }

    /* Add checkmark icon when checked */
    &:checked::before {
      content: "";
      position: absolute;
      left: 6px;
      top: 1px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    /* Add hover effect */
    &:hover {
      border-color: #999; /* Border color on hover */
    }
  }
  .all-select {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
  .category {
    padding-bottom: 1rem;
    border-bottom: 1px solid $lgray;

    h4 {
      font-size: 15px;
      font-weight: 600;
    }
  }

  li {
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    margin: 0;
    gap: 1rem;
    cursor: pointer;
    &:first-child {
      padding-top: 1rem;
    }
    h5 {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    input[type="checkbox"] {
      margin-right: 1rem;
    }
  }

  div {
    margin-bottom: 1rem;
  }

  h4 {
    margin-left: 1rem;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      padding-left: 1.5rem;

      &:hover {
        cursor: pointer;
        background-color: #eaeaea;
      }
    }
  }
}

.hide-on-mobile {
  display: none !important;
  @include respond-to(md) {
    display: block !important;
  }
}
