.guests-amount-picker {
  padding: 1rem;
  width: fit-content;
  background-color: white;
  width: 100%;
  .modal-body {
    .title {
      margin-bottom: 2rem;
    }
    .guest {
      @include flexBetween(row);
      align-items: start;
      margin-bottom: 1rem;
      &.infants {
        margin-bottom: 0rem;
      }
      &.pet {
        padding-top: 1rem;
        border-top: 1px solid $lgray;
      }
      .name {
        p {
          font-size: 0.8rem;
          color: $gray;
        }
        .link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .qty {
        @include flexCenter(row);
        gap: 1rem;
        margin-left: 2rem;
        p {
          margin: 0;
        }
        button {
          @include flexCenter(row);
          margin: 0;
          border: 1px solid $lgray;
          background-color: white;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          cursor: pointer;
          &:hover {
            background-color: $lgray;
          }
          &:disabled {
            color: $lgray;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
