.map-card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  pointer-events: auto;
  z-index: 9999; /* Ensure it appears above other elements */
  transform: translate(-50%, -110%);
  width: 300px;
  height: fit-content;
  overflow: hidden;
  cursor: pointer;
  .image-container {
    position: relative;
    width: 100%;
    .card-navigation {
      padding: 0 1rem;
      width: 100%;
      @include flexBetween(row);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      opacity: 1;
      transition: all 0.2s;
      z-index: 10;
      button {
        background-color: white;
        color: black;
        border: none;
        border-radius: 100%;
        cursor: pointer;
        transition: all 0.2s;
        img {
          width: 5px;
          height: 10px;
        }
        &:hover {
          background-color: black;
          color: white;
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}
.map-card-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
