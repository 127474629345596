.different-name-modal {
  .form {
    .input-container {
      position: relative !important;
      width: 100%;
      .input,
      input {
        width: 100%;
      }
    }
  }
}
