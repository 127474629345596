.request-to-book {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 0px;
  @include respond-to(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.back-button {
  @include flexStart(row);
  gap: 1rem;
  margin-bottom: 2rem;
  b {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.trip-details {
  .option {
    @include flexBetween(row);
    .title {
      @include flexStart(column);
      align-items: start;
    }
  }
}
.payment-method {
  margin-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $lgray;
  .title {
    @include flexBetween(row);
    margin-bottom: 2rem;
    ul {
      @include flexCenter(row);
      list-style-type: none;
      gap: 1rem;
      margin: 0;
      li {
        @include flexCenter(row);
      }
    }
  }
  .form {
    .input {
      position: relative;
      width: 100%;
      flex-direction: column;
      label {
        position: absolute;
        color: $gray;
        font-size: small;
        top: 0.5rem;
        left: 1rem;
      }
      input,
      select {
        border: 1px solid #ccc;
        display: flex;
        padding: 1rem;
        padding-top: 1.5rem;
        width: 100%;
      }
      .card-number {
        margin-top: 1rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
      .outside-input {
        border-radius: 0.5rem;
        border-radius: 0;
      }
    }
    .double-input {
      display: flex;
      .input {
        input {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        .exp {
          border-bottom-left-radius: 0.5rem;
        }
        .cvv {
          border-bottom-right-radius: 0.5rem;
        }
      }
    }
  }
}
.message {
  .input-container {
    width: 100%;
  }
  textarea {
    width: 100% !important;
  }
}
.price-details {
  border-radius: 1rem;
  border: 1px solid $gray;

  .preview {
    @include flexStart(row);
    padding: 1rem;
    align-items: start;
    gap: 1rem;
    img {
      width: 165px;
      height: 100px;
      object-fit: cover;
      border-radius: 1rem;
    }
    h5 {
      font-weight: 700;
    }
  }
  .seperator-sm {
    padding-bottom: 0;
  }
  .details {
    padding: 1rem;
    .item {
      @include flexBetween(row);
      align-items: start;
      b {
        font-weight: 500;
      }
      b.minus {
        color: $blue;
      }
    }
  }
}
.total {
  background-color: $lblue;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 1rem;
  @include flexBetween(row);
  h3 {
    font-weight: 600;
  }
}
.alert {
  .minus {
    color: $blue;
    font-weight: 500;
  }
  p {
    font-size: 0.7rem;
    font-weight: 400;
    color: $gray;
  }
}
