.reset-password-page {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  .back-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  h2 {
    font-size: 36px;
    font-weight: 500;
    margin: 0;
  }
  .input-container {
    @include flexStartLeft(column);
    align-items: start;
    gap: 1rem;
    position: relative;
    label {
      font-size: 16px;
      font-weight: 400;
      color: $gray;
    }
    input {
      border-radius: 0.5rem;
      padding: 1rem 1.5rem;
      width: 350px;
      border: 1px solid $lgray;
      &:focus {
        border: 1px solid black;
      }
    }
  }
  button {
    background-color: black;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: $gray;
    }
    &:disabled {
      background-color: $lgray;
      cursor: not-allowed;
    }
  }
}
