.modal-content {
  background-color: white;
  text-align: center;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      margin: 0;
    }
    .close-icon {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .body {
    form {
      p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
      }
    }
    .already {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      a {
        color: $blue;
      }
    }
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .form-group {
    margin-bottom: 15px;
    text-align: left;
    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    select {
      border-radius: 0.5rem;
      border: 1px solid $gray;
      padding: 0.5rem 1rem;
      // width: 15rem;
      width: 100%;
    }
  }

  .create-account-button {
    width: 100%;
    border-radius: 1.5rem;
  }

  .social-login {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: 20px;

    .social-button {
      padding: 1rem;
      border-radius: 4px;
      width: 60px;
      height: 60px;
      border: 1px solid #ddd;
      cursor: pointer;
      background-color: $white;
      @include flexCenter(row);
    }
  }
}
.line-behind {
  position: relative;
  background-color: white;
  &::before {
    content: "";
    width: 9rem;
    height: 1px;
    background-color: $lgray;
    position: absolute;
    top: 50%;
    transform: translate(-110%, -50%);
  }
  &::after {
    content: "";
    width: 9rem;
    height: 1px;
    background-color: $lgray;
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
  }
}

.login-links {
  @include flexCenter(column);
}
