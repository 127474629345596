.side-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 40%;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.5rem;
  padding-bottom: 3rem;
  h3 {
    margin-bottom: 1rem;
  }
  .side-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid $lgray;
    &:hover {
      text-decoration: underline;
    }
  }
}
@media (max-width: 930px) {
  .side-modal {
    width: 100%;
  }
}
