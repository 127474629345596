.gallery-view-card {
  width: max-content;
  .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0.3rem;
    grid-row-gap: 0.3rem;
    border-radius: 0.5rem;
    overflow: hidden;
    @include shadow();
    .img {
      width: 150px;
      height: 100%;
      object-fit: cover;
      background-color: $lgray;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
