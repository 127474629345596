$section_margins: 10rem;
$mobile_section_margins: 5rem;

/*Hero Image start*/
.get-the-app .hero-image-container {
  margin-bottom: 2rem;
  height: auto;
  .hero-image {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 992px) {
  .get-the-app .hero-image-container {
    height: 300px;
    .hero-image {
      object-fit: cover;
    }
  }
}
/*Hero Image end*/
/*App Descriptions start*/

.get-the-app .app-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $section_margins;
}

@media (max-width: 930px) {
  .get-the-app .app-description {
    margin-bottom: $mobile_section_margins;
  }
}
/*App Descriptions end*/
/*QR code start*/

.get-the-app .qr-code-section {
  margin-bottom: $section_margins;
  background-color: $lwhite;
  display: flex;
  justify-content: center;
  .contents {
    position: relative;
    background-color: $lwhite;
    display: flex;
    justify-content: center;
    gap: 8rem;
    .phone-image-container {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 25%;
      transform-origin: left center;
      .phone-image {
        width: 100%;
        object-fit: contain;
      }
    }
    .description {
      margin-left: 22rem;
      display: flex;
      justify-content: space-between;
      gap: 5rem;
      .text {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
    .qr-code-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-end;
      width: 10rem;
      margin-top: 10rem;
      .qr-code {
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .get-the-app .qr-code-section {
    margin-bottom: $mobile_section_margins;
    .contents {
      flex-direction: column;
      gap: 2rem;
      .phone-image-container {
        position: relative;
        top: auto;
        left: auto;
        transform: translateY(0);
        width: 50%;
        margin: auto;
        .phone-image {
          width: 100%;
        }
      }
      .description {
        margin-left: 0rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .text {
          width: 80%;
          text-align: center;
          h3 {
            text-align: center;
          }
        }
      }
      .qr-code-container {
        margin-top: 0rem;
        align-self: auto;
        width: 40%;
      }
    }
  }
}

/*QR code end*/
/*ManageStaying start*/
.get-the-app .manage-satying {
  margin-bottom: $section_margins;
  .features {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    .feature {
      width: 25%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      justify-content: space-evenly;
    }
  }
}
@media (max-width: 930px) {
  .get-the-app .manage-satying {
    margin-bottom: $mobile_section_margins;
    h2 {
      text-align: center;
    }
    .features {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      .feature {
        text-align: center;
        width: 100%;
        gap: 0;
        h3 {
          text-align: center;
        }
      }
    }
  }
}
/*ManageStaying end*/
