.search-section {
  display: none;
  @include respond-to(md) {
    display: block;
  }
}
.search-bar {
  background-color: white;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 14px;
  @include shadow();
  border-radius: 1rem;
  padding: 17px 30px;
  z-index: 5;
  transform: translateY(-30%);
  transition: 0.3s;
  width: 100%;
  margin: 0 auto;
  display: none;
  & > * {
    grid: 2;
  }
  .div1 {
    grid-area: 1 / 1 / 3 / 2;
  }
  .div2 {
    grid-area: 3 / 1 / 5 / 2;
  }
  .div3 {
    @include respond-to(lg) {
      display: flex;
      grid-area: 5 / 1 / 7 / 2;
    }

    @media (max-width: 990px) and (max-height: 933px) {
      display: block;
    }
  }
  @include respond-to(xxl) {
    position: sticky;
    top: 0;
    grid-template-columns: repeat(4, 1fr) 86px;
    transform: translateY(-50%);
    width: 82%;
  }
  @include respond-to(md) {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 86px;
    grid-column-gap: 10px;
  }
  @include respond-to(xs) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    padding: 15px;
  }
  .div4 {
    margin-left: 7px;
    grid-area: 4 / 1 / 5 / 2;
  }

  .div1,
  .div2,
  .div3,
  .div4 {
    grid-area: auto;
  }

  label {
    font-weight: 500;
    height: 18px;
    margin-bottom: 9px;
    display: block;
    transition: 0.3s;
  }

  .show-date-picker {
    font-weight: 500;
    @include respond-to(md) {
      svg {
        display: none;
      }
    }
    @include respond-to(lg) {
      svg {
        display: block;
      }
    }
  }
}
.search-bar .submit-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  margin-top: 1rem;
  @include respond-to(md) {
    margin-top: 0;
    height: 2.6rem;
    width: 3rem;
  }
  @include respond-to(lg) {
    height: 3.1rem;
    width: auto;
  }
}
.drop-down {
  position: relative;
}
.search-item {
  position: relative;
  width: 100%;
  align-items: flex-start !important;
  align-content: end;
  .collapse-location {
    top: 4.6rem;
    min-width: 100%;
    width: max-content;
  }
  .show-date-picker {
    transition: all 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    gap: 1rem;
    margin: 0;
    border: 1px solid $lgray;
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    text-wrap: nowrap;
  }
  .show-amenities-picker {
    transition: all 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    border: 1px solid $lgray;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    text-wrap: nowrap;
    h5 {
      margin: 0;
      font-size: 0.9rem;
      padding: 0.3rem 0.5rem;
      border-radius: 0.3rem;
    }
    h4 {
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      padding: 0.3rem;
      border-radius: 0.3rem;
    }
    h6 {
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  .collapse-date {
    top: 4.6rem;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 1rem;
  }
  .collapse-guest {
    top: 4.6rem;
    width: max-content;
    border-radius: 1rem;
    padding: 0.5rem;
    background-color: white;
  }

  .round-svg {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 50%;
  }
}
.item-amenities-picker {
  display: none;
  @include respond-to(md) {
    display: block;
  }
}
.container.collapsed {
  @include respond-to(md) {
    transition: 0.5s;
    position: sticky;
    top: 8rem;
    z-index: 9;
    label {
      height: 0px;
      opacity: 0;
    }
    .search-bar {
      grid-template-columns: repeat(4, 1fr) 56px;
      width: 85%;
      padding: 11px 20px;
      button > svg {
        // display: none;
      }
      @include respond-to(lg) {
        button > svg {
          display: block;
        }
      }
    }
    .submit-button {
      padding: 7px;
      @include respond-to(lg) {
        padding: 13px;
      }
      :hover {
        img {
          filter: invert(1);
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .container.collapsed {
    .search-bar {
      width: 80%;
    }
  }
}

@media (max-width: 1200px) {
  .container.collapsed {
    .search-bar {
      width: 100%;
    }
  }
}
