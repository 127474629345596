.reviews {
  margin-bottom: 2rem;
  .cancel-button {
    padding: 0.5rem 3rem;
    width: max-content;
  }
}
.reviews-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  margin-top: 2rem;
  > * {
    flex: 1;
  }
  @include respond-to(lg) {
    flex-direction: row;
    gap: 10rem;
  }
}

.title {
  b {
    span {
      border-left: 1px solid $lgray;
      padding-left: 1rem;
      margin-left: 1rem;
    }
  }
}
