// Navbar Styles
.navbar {
  position: absolute;
  width: 100%;
  padding: $navbar-padding;
  top: 0;
  z-index: 10;
  transition: 0.5s;
  .container {
    z-index: 3;
    position: relative;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo img {
  height: 28px;
  object-fit: contain;
  @include respond-to(md) {
    height: 35px;
  }
}

.navbar-links {
  flex-grow: 1;
  display: none;
  justify-content: center;
  color: $white;

  a {
    color: inherit;
    margin: 0 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  @include respond-to(lg) {
    display: flex !important;
  }
}

.navbar-right {
  display: flex;
  align-items: center;

  .nav-btn {
    background-color: $white;
    color: $black;
    padding: $navbar-button-padding;
    height: 48px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 17px;
    cursor: pointer;
    margin-left: 16px;
    border: none;
    &.hover {
      padding: 1rem;
      display: none;
      @include respond-to(md) {
        display: flex;
        padding: inherit;
        padding: 1rem;
      }
      svg {
        width: 25px;
        height: 25px;
        stroke: black;
        @include respond-to(md) {
          width: auto;
          height: auto;
        }
      }
      &:hover {
        @include respond-to(md) {
          background-color: black;
          svg {
            stroke: white !important;
          }
        }
      }
    }
    .nav-btn {
      padding: 9px;
      height: 39px;
      margin-right: -11px;
      border: 1px solid $black;
      &:hover {
        background-color: $white;
      }
    }

    &.lg {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }

    &.dark {
      display: none;
      color: $white;
      background-color: $black;
      &:hover {
        background-color: $white;
        color: black;
        img {
          filter: brightness(0);
        }
      }
      @include respond-to(sm) {
        display: flex;
      }
    }
  }

  .notifications-indicator {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: $sred;
    @include flexCenter(row);
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  .navbar-buttons {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .app-button,
    .menu-button {
      color: $white;
      border: none;
      padding: $navbar-button-padding;
      border-radius: $border-radius;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        background-color: $hover-bg-color;
      }
    }
  }
}
.user-dropdown a.navbar-link-mobile {
  display: block;
  @include respond-to(lg) {
    display: none !important;
  }
}

.get-the-app-button {
  @include respond-to(md) {
    display: none !important;
  }
}

.notification-link {
  width: 100%;
  a {
    @include flexBetween(row);
  }
  .notification-dot {
    background-color: $sred;
    @include flexCenter(row);
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
}
