.footer {
  background-color: $black;
  padding: 26px 0;
  position: relative;
  z-index: 10;
  .container {
    display: grid;
    gap: 1rem;
    @include respond-to(lg) {
      grid-template-columns: 2fr 1fr;
    }
  }
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include respond-to(lg) {
      margin-right: auto;
    }
  }
  p {
    color: $gray;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
  }
  ul {
    @include flexCenter(row);
    gap: 1rem;
    margin: 0;
    list-style-type: none;
    @include respond-to(lg) {
      margin-left: auto;
    }
    li {
      a {
        color: white;
        font-size: 1.5rem;
        &:hover {
          color: $gray;
        }
      }
    }
  }
}
