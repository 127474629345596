.room-facilities {
  @include flexCenter(row);
  justify-content: start;
  .facility {
    padding: 0 0.2rem !important;
    background-color: $slgray;
    .more {
      color: $sblue;
    }
    p {
      margin: 0 !important;
    }
  }
}

.show-amenities {
  color: $sblue;
  font-weight: 500;
  cursor: pointer;
  text-align: start;
  background-color: transparent;
  border: none;
  margin-bottom: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
}

.amenities-modal {
  @include flexStart(column);
  align-items: start;
  width: 100%;

  .amenity-list {
    list-style: none;
    border-bottom: 1px solid $lgray;
    width: 100%;
    margin: 0;
    padding-bottom: 1rem;
  }
  .amenity-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .icon-preview {
    @include flexStart(row);
    gap: 1rem;
    .preview {
      width: 24px;
      height: 24px;
      overflow: hidden;
      border-radius: 0.5rem;
      margin: 0;
      svg {
        stroke: black;
        fill: none;
      }
    }
  }
}
