.image-upload-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  .custom-file-upload {
    @include flexCenter(row);
    gap: 1rem;
    width: 100%;
    background-color: $lblue;
    border: 1px dashed $blue;
    color: $blue;
    cursor: pointer;
    padding: 4rem;
    img {
      width: 25px;
      height: 25px;
    }
  }

  input[type="file"] {
    display: none; // Hide the default file input
  }

  .image-preview {
    margin-top: 10px;
    img {
      max-width: 200px;
      max-height: 200px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
}
.add-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  @include flexCenter(row);
  .body {
    position: relative;
    width: 500px;
    background-color: white;
    padding: 1rem;
    height: auto;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      font-size: 1.3rem;
      margin: 1rem;
    }
  }
}
