.card-container-sm {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */

  /* Hide scrollbar */
  scrollbar-width: none; /* For Firefox */
}

.card-container-sm::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.outside-container {
  @include flexCenter(column);
  justify-content: center;
}
.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button-container {
  display: flex;
  gap: 0.8rem;
}

.scroll-container {
  overflow: hidden; /* Hide scrollbar */
  position: relative;
  display: flex;
  gap: 0.5rem;
  /* For positioning buttons */
}
/* Hide the scroll buttons on smaller screens */
@media (max-width: 768px) {
  /* You can adjust the max-width value */
  .scroll-container {
    padding: 0; /* Remove padding to avoid unnecessary space */
  }

  .scroll-button {
    display: none; /* Hide the scroll buttons */
  }
}

.scroll-button {
  background-color: transparent;
  border: hidden;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 2px solid gray;
  }
}

// .serch-section{
//   position: sticky;
//   top: 0;
//   z-index: 1000;
// }

.loading-home-page {
  min-height: 100vh;
  position: absolute;
  inset: 0;
  @include flexCenter(column);
  z-index: 1000;
  background-color: white;
  .loading-home-page-img {
    height: 70px;
    img {
      height: 100%;
      object-fit: contain;
      animation: breathing 2s ease-in-out infinite;
    }
  }
}

@keyframes breathing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-row-gap: 1rem;
  margin: 2rem 0;

  > * {
    flex: 1;
  }

  @include respond-to(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }

  @include respond-to(lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
  }

  @include respond-to(xl) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
  }

  @include respond-to(xxl) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 0.5rem;
  }

  .card-container-responsive {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 2rem;
  }
}

.allPropertiesButton {
  width: 100%;
  @include flexCenter(row);
}

button.left-button,
button.right-button {
  position: absolute;
  top: 6rem;
  z-index: 10;
  background-color: $white;
  color: black;
  border: none;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 0.2s;
  img {
    width: 5px;
    height: 10px;
  }
  &:hover {
    background-color: black;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }
}

button.left-button {
  left: 1.2rem;
  opacity: 0;
}

button.right-button {
  right: 1.2rem;
  opacity: 0;
}

.card {
  @include flexStartLeft(column);
  border-radius: 1rem;
  background-color: $white;
  transform: scale(1);
  display: flex;
  transition: all 0.2s;
  flex-direction: column;
  &:hover {
    transform: scale(1);
    & button.left-button {
      opacity: 1;
    }
    & button.right-button {
      opacity: 1;
    }
  }
  @include respond-to(md) {
    opacity: 1;
    &:hover {
      @include shadow();
      transform: scale(1.05);
      .card-navigation {
        opacity: 1;
      }
    }
  }
  .card-link {
    color: $black;
    text-decoration: none;
    flex: 1;
    &:hover {
      text-decoration: underline;
    }
  }
}

.card-image {
  position: relative;
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.card-image .hotel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.card-icons .icon {
  background-color: rgb(255 255 255 / 30%);
  color: $black;
  border-radius: 50%;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: $black;
    color: $white;
    cursor: pointer;
    img {
      filter: brightness(0) invert(1);
    }
  }
  &.active {
    background-color: $black;
    color: $white;
    cursor: pointer;
    img {
      filter: brightness(0) invert(1);
    }
  }
}
.card-navigation {
  padding: 0 1rem;
  width: 100%;
  @include flexBetween(row);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.2s;
  @include respond-to(md) {
    opacity: 0;
  }
  button {
    background-color: $white;
    color: black;
    border: none;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all 0.2s;
    img {
      width: 5px;
      height: 10px;
    }
    &:hover {
      background-color: black;
      color: white;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.card-content {
  padding: 14px 20px 14px 20px;
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // @include respond-to(md) {
  //   min-height: 150px;
  // }
  .lowest-price {
    color: $black;
    font-weight: 500;
    font-size: 14px;
  }
}

.hotel-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  min-width: 114px;
  cursor: pointer;
  height: 80px;
  span {
    transition: all 0.2s;
  }

  &:hover {
    span {
      color: $black;
    }
    svg {
      stroke: black;
    }
  }
}

.category-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
  svg {
    stroke: $gray;
    fill: none;
  }
}

.category-text {
  font-size: 12px;
  font-weight: 114px;
  color: $gray;
  text-transform: uppercase;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(1.2em * 2);
  width: 100%;
  text-wrap: wrap;
  text-overflow: clip;
}

@media screen and (max-width: 768px) {
  .heading-container {
    ~ .card-container-sm {
      background: #ffffff;
      .hotel-category {
        padding: 10px 8px;
        min-width: 90px;
      }
    }
    .headline-main {
      display: none;
    }
  }
  .navbar {
    position: fixed !important;
    background: #ffffff;
    padding: 22px 0 10px !important;
  }
  .header-margin {
    margin-top: 78px !important;
    ~ .sticky {
      position: sticky !important;
      z-index: 9;
      padding: 18px 10px 10px;
      background: #ffffff;
      top: 78px;
    }
  }
}
