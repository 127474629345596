.hotel-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 1rem;
  @include respond-to(lg) {
    grid-template-columns: 3fr 1fr;
    gap: 2rem;
  }
}
.guest-favourite {
  border: 1px black solid;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  overflow: hidden;
  width: 100%;
  p,
  h4 {
    margin: 0;
  }
  @include respond-to(lg) {
    flex-direction: row;
    gap: 1rem;
  }
  .title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    h4 {
      padding: 0 2rem;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
    }
    .rippon {
      position: absolute;
      top: 0;
      left: 1rem;
    }
  }
  .description {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1rem 0 1rem;
    margin: 0;
    gap: 1rem;
    p {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }
    @include respond-to(lg) {
      flex-direction: row;
      gap: 1rem;
      padding: 0 1rem;
      p {
        width: 70%;
        text-align: left;
        margin-right: auto;
      }
    }
  }
  .review-number {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.availability {
  @include shadow();
  height: max-content;
  @include respond-to(lg) {
    min-width: 400px;
    max-width: 400px;
  }
  .div1 {
    text-align: center;
    @include flexCenter(row);
    background-color: $black;
    color: $white;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    padding: 1rem;
    p {
      margin: 0;
    }
  }

  .div2 {
    @include flexBetween(row);
    padding: 0rem 2rem;
    background-color: $lgray;
  }
  .div3 {
    position: relative;
    @include flexCenter(row);
    cursor: pointer;
    border-bottom: 1px solid $lgray;
    &:hover {
      background-color: $slgray;
    }
  }
  .div5 {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid $lgray;
    padding: 1rem 0;
    select {
      width: 100%;
      border-radius: 0.5rem;
      border: 1px solid $lgray;
      padding: 0.5rem;
    }
    &:hover {
      background-color: $slgray;
    }
    .guests {
      @include flexStartLeft(column);
      padding: 0.5rem 1rem;
    }
  }
  .div6 {
    padding: 1rem;
    border-bottom: 5px solid black;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    .submit-button {
      width: 100%;
    }
  }
}

.check {
  @include flexCenter(column);
  text-align: center;
  padding: 1rem;
  p {
    text-wrap: nowrap;
  }
  h4 {
    text-wrap: nowrap;
  }
  &:first-of-type {
    border-right: 1px solid $lgray;
  }
}

.description {
  a {
    color: $blue;
  }
}
.collapse-date {
  position: absolute;
  z-index: 10;
  top: 6rem;
  @include shadow();
  width: 100%;
}
.collapse-guest {
  @extend .collapse-date;
  top: 6rem;
  left: 0;
}
