.password-container {
  position: relative !important;
  input {
    border-radius: 0.5rem;
    border: 1px solid $gray;
    padding: 0.5rem 1rem;
  }
  .show-password {
    margin: 0 !important;
    position: absolute;
    top: 50% !important;
    right: 0.5rem !important;
    transform: translateY(-50%);
    background-color: transparent !important;
    border: none !important;
    color: black !important;
    cursor: pointer;
  }
}
