header {
  height: 500px;
  position: relative;
  align-items: center;
  display: none;
  @include respond-to(md) {
    display: grid;
  }
  .container-sm * {
    z-index: 2;
    color: #fff;
    position: relative;
  }
  .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(170, 170, 170, 0) 46.25%,
      rgba(12, 12, 12, 0.74) 100%
    );

    z-index: 1;
  }
  > img {
    position: absolute;
    width: 100vw;
    height: 500px;
    object-fit: cover;
    z-index: 1;
    top: 0;
    right: 0;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 2rem;
  }
}

.navbar-container {
  /* ---------------------------------------
     Base styles
  ---------------------------------------- */
  .logo {
    * {
      fill: #1a1a1a;
    }
  }

  .logo-dark {
    display: block;
  }

  /* ---------------------------------------
     Sub-base-route state
  ---------------------------------------- */
  &.sub-base-route {
    .navbar {
      background-color: #efe9e4;
    }

    .navbar-links {
      color: $black;
    }

    .logo {
      * {
        fill: #1a1a1a;
      }
    }

    .logo-dark {
      display: block;
    }
  }

  /* ---------------------------------------
     Sub-base-route-scrolled state
  ---------------------------------------- */
  &.sub-base-route-scrolled {
    .navbar {
      padding: 0.8rem;
    }

    .navbar-links {
      display: none !important;
    }
  }

  /* ---------------------------------------
     Media Queries
  ---------------------------------------- */
  @include respond-to(md) {
    /* Adjustments for medium screens and up */
    .logo {
      display: block;
      * {
        fill: #efe9e4;
      }
    }

    .logo-dark {
      display: none;
    }

    /* Fixed navbar on scroll or specific condition */
    &.fixed {
      .navbar {
        position: fixed;
        top: 0;
        background-color: #efe9e4;
        z-index: 300;
      }

      .navbar-links {
        color: $black;
      }

      .logo {
        // If you need to hide for fixed state, uncomment
        // display: none;
        transition: all 0.3s ease-in-out;
        * {
          fill: #1a1a1a;
        }
      }

      .logo-dark {
        display: block;
      }
    }
  }
}
