.property-types {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;

  @include respond-to(md) {
    grid-template-columns: repeat(1, 1fr);
  }
  grid-row-gap: 10px;
  width: 100%;
  justify-items: center;
  .type {
    @include flexStart(column);
    padding: 1rem;
    gap: 0.5rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 0.5rem;
    height: 100px;
    width: 100px;
    @include respond-to(md) {
      @include flexStart(row);
      width: 100%;
      height: 50px;
    }
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    svg {
      stroke: black;
    }
    &.selected {
      color: white;
      background-color: black;
      svg {
        stroke: white;
      }
    }
  }
}
