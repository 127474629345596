.booking-successful-page {
  margin-top: -3rem;
  min-height: 75vh;
  @include flexCenter(column);
  & > p {
    width: 600px;
    text-align: center;
  }
  .sections {
    margin-top: 2rem;
    @include flexCenter(row);
    gap: 3rem;
    text-align: center;
  }
}
