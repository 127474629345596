.facilities {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0rem;
  grid-row-gap: 0px;
  gap: 1rem;

  @include respond-to(lg) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
  }
  .facility {
    @include flexStartLeft(row);
    background-color: $lgray;
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    gap: 0.5rem;
    width: 100%;
    .facility-icon {
      @include flexCenter(row);
      .preview {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 0.5rem;
        margin: 0;
        svg {
          stroke: black;
          fill: none;
        }
      }
    }
    p {
      font-size: 0.7rem;
      text-wrap: nowrap;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      text-overflow: ellipsis;
    }
  }
}
