.m-1 {
  margin: 5px;
}

.m-2 {
  margin: 10px;
}

.m-3 {
  margin: 15px;
}

.m-4 {
  margin: 20px;
}

.m-5 {
  margin: 25px;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

/* Margin bottom */
.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.mx-1 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-3 {
  margin-left: 15px;
  margin-right: 15px;
}

.mx-4 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-5 {
  margin-left: 25px;
  margin-right: 25px;
}

.my-1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-5 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.seperator {
  width: 100%;
  height: 1px;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $lgray;
}

.seperator-sm {
  @extend .seperator;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}