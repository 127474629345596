.button-container {
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-top: 1px solid $gray;
  width: 100%;
  display: flex;
  justify-content: end;
  .button-link {
    @include flexCenter(row);
  }
  .submit-button {
    margin-left: auto;
  }
}

.submit-button {
  @include flexCenter(row);
  margin-top: 1rem;
  width: max-content;
  text-decoration: none;
  background-color: black;
  color: $white;
  border: 1px solid $black;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: white;
    color: $black;
    img {
      filter: invert(1);
    }
  }
  &:disabled {
    background-color: $gray;
    cursor: not-allowed;
  }
}

.search-button {
  @extend.submit-button;
  width: 100%;
  text-align: center;
}

//link looks like button
.link-button {
  text-decoration: none;
  font-size: small;
  background-color: black;
  color: $white;
  border: none;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: rgb(53, 48, 48);
  }
  &:disabled {
    background-color: $gray;
    cursor: not-allowed;
  }
}
.cancel-button {
  @include flexCenter(row);
  gap: 0.5rem;
  margin-top: 1rem;
  width: max-content;
  background-color: white;
  color: $black;
  font-weight: 500;
  border: none;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  border: 1px $black solid;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    background-color: rgb(53, 48, 48);
    border: 1px $black solid;
    color: $white;
  }
  &:disabled {
    background-color: $gray;
    cursor: not-allowed;
  }
}

.gray-button {
  background-color: $lgray;
  color: $black;
  border: none;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: rgb(53, 48, 48);
    color: white;
  }
}

//
.edit-button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 500;
}

.add-button {
  @extend .edit-button;
  color: $blue;
  text-decoration: none;
  @include flexStart(row);
  gap: 0.5rem;
}

//button looks like link
.button-link {
  margin: 1rem 0;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  color: black;
  &:hover {
    color: rgb(53, 48, 48);
    cursor: pointer;
  }
  &.blue {
    color: $blue;
  }
}
//link nad has arow beside it

.arrow-link {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: transparent;
  border: none;
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.btn {
  @extend .cancel-button;
  display: inline-block;
}

.closeIcon {
  cursor: pointer;
}
