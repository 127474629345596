/* For Chrome, Edge, and Safari */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;    /* Color of the scrollbar thumb */
    border-radius: 10px;       /* Rounded edges */
    border: 2px solid #f1f1f1; /* Space around the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;    /* Darken on hover */
  }
  
  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
  
  /* For Internet Explorer and older Edge versions */
  .scrollbar {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  