.tabs-bar {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid $gray;
  .tab {
    font-weight: 500;
    text-align: left;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    color: $gray;
  }
  .tab.active {
    border-bottom-color: $black;
    color: black;
  }
}

.tab-container {
  padding-top: 2rem;
}
