.share-modal {
  width: 100%;
  img {
    height: 100px;
    width: 150px;
    object-fit: cover;
  }
  .property {
    @include flexStartLeft(row);
    gap: 1rem;
  }
  .share-buttons {
    @include flexStartLeft(row);
    gap: 1rem;
  }
}
