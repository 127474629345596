@font-face {
  font-family: "ScaleVF";
  src: url("/assets/fonts/scale-vf.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.03em;
}

// Global Styles
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0.03em;
  scroll-behavior: smooth;
  font-family: var(--font-poppins);
}
svg {
  max-width: 80px;
}
.nl {
  text-decoration: none;
}

#root {
  min-height: 100vh;
  display: grid;
  align-items: center;
  align-content: space-between;
}

body,
html {
  max-width: 100vw;
}

.nogap {
  gap: 0 !important;
}

// Container Styles
.container {
  position: relative;
  width: 90vw;
  margin: 0 auto;
  padding: $container-padding;
  @include respond-to(sm) {
    max-width: 540px;
  }

  @include respond-to(md) {
    max-width: 720px;
  }

  @include respond-to(lg) {
    max-width: 960px;
  }

  @include respond-to(xl) {
    max-width: 1140px;
  }
  &.big {
    @include respond-to(xxl) {
      max-width: 2360px;
    }
  }
}

.container-sm {
  width: 90vw;
  margin: 0 auto;
  padding: $container-padding;
  position: relative;
  @include respond-to(sm) {
    max-width: 540px;
  }

  @include respond-to(md) {
    max-width: 540px;
  }

  @include respond-to(lg) {
    max-width: 720px;
  }

  @include respond-to(xl) {
    max-width: 900px;
  }

  @include respond-to(xxl) {
    max-width: 1080px;
  }
}

.container-xs {
  width: 650px;
  margin: 0 auto;
  padding: $container-padding;
}

// Typography
h1,
h2 {
  font-family: "ScaleVF", sans-serif;
  text-align: left;
}

h3,
h4,
h5,
h6 {
  text-align: left;
}
p {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 0.67em;

  &.sm {
    font-size: 12px;
  }
}

.light {
  color: $gray !important;
}

hr {
  background-color: $lgray;
  border: unset;
  height: 1px;
}
h1 {
  font-weight: 500;
  font-size: 36px; // Adjusted for consistency
  line-height: 1.2; // Improved readability
  margin-bottom: 0.5em; // Slightly reduced for a balanced look
}

h1.big {
  font-size: 48px; // Slightly reduced to maintain balance
  line-height: 1.2; // Consistent with other headings
}

h2 {
  font-weight: 500;
  font-size: 30px; // Adjusted for consistency with h1
  line-height: 1.2; // Consistent spacing
  margin-bottom: 0.5em; // Matching with h1 for balance
}

h3 {
  font-weight: 500;
  font-size: 24px; // Slightly increased for better hierarchy
  line-height: 1.3; // Adjusted for readability
  margin-bottom: 0.5em; // Consistent with other headings
}

h4 {
  font-weight: 500;
  font-size: 20px; // Adjusted for a smoother hierarchy
  line-height: 1.3; // Consistent with h3
  margin-bottom: 0.5em; // Unified margin
}

h5 {
  font-weight: 500;
  font-size: 16px; // Standard size for h5
  line-height: 1.4; // Increased for better readability at smaller size
  margin-bottom: 0.5em; // Added margin for consistency
}

ul {
  margin: 1rem 0 2rem 1.5rem;

  li {
    margin: 0.5rem 0;
    text-align: justify;
  }
}

.center {
  text-align: center;
}

.disabled * {
  color: $gray;
}

.danger {
  color: $sred;
}
.success {
  color: $green;
}
.hidden {
  display: none;
}
