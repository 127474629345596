.chats-container {
  height: 50vh;
  overflow: auto;
  padding-bottom: 2rem;
  .chat {
    cursor: pointer;
    @include flexBetween(row);
    gap: 1rem;
    padding: 1rem 0rem;
    &.selected {
      background-color: $slgray;
    }
    &:hover {
      background-color: $slgray;
    }
    .img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: $lgray;
      @include flexCenter(row);
      font-size: 1.5rem;
      font-weight: bolder;
    }
    .text {
      @include flexStartLeft(row);
      justify-content: space-between;
      h5 {
        text-transform: capitalize;
      }
    }
  }
}
