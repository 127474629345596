.duration-date-picker {
  font-family: poppins;
  height: max-content;
  background-color: white;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .day-picker {
    width: fit-content;
    margin: 1.5rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    width: 80%;
    margin: 0 1.5rem;
    span {
      color: $gray;
      font-size: 0.9rem;
    }
  }

  .months-slider {
    margin: 1.5rem;
    width: 80%;
    height: fit-content;
    position: relative;
    input {
      width: 100%;
      height: 20px;
      border-radius: 30px;
      accent-color: black;
      -webkit-appearance: none;
      appearance: none;
      position: relative;
    }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
      border: 1px solid gray;
      transform: translate(-5px, -5px);
      position: relative;
    }
    input[type="range"]::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      appearance: none;
      border-radius: 50%;
      height: 20px;
      cursor: pointer;
      appearance: none;
      position: relative;
      display: hidden;
    }
    input[type="range"]::-moz-range-thumb {
      -moz-appearance: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
      transform: translate(-5px, 0);
      position: relative;
    }
    input[type="range"]::-moz-range-thumb::after {
      content: "2";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: red;
      z-index: 100;
    }
    input[type="range"]::-moz-range-track {
      -webkit-appearance: none;
      appearance: none;
      border-radius: 50%;
      height: 20px;
      cursor: pointer;
      appearance: none;
      position: relative;
      display: hidden;
    }
    input[type="range"]::-ms-thumb {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
    }

    .indicator {
      position: absolute;
      top: 50%;
      span {
        text-align: center;
      }
    }
  }
}
.rdp-root {
  font-family: poppins;
  --rdp-accent-color: black !important; /* Change the accent color to indigo. */
}
